// NotFoundPage.js
import React from "react";
import "./NotFoundPage.scss";

const NotFoundPage = () => {
  return (
    <div className="not-found-page">
      <h1>Page Not Found</h1>
      <p>We're sorry, but the page you're looking for doesn't exist.</p>
    </div>
  );
};

export default NotFoundPage;
