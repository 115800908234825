import "antd/dist/antd.min.css";
import "antd/dist/antd.variable.min.css";
import { createRoot } from "react-dom/client"; // Import createRoot from react-dom/client
import App from "./App";
import ErrorBoundary from "./ErrorBoundary/ErrorBoundary";
import ConfigProviderComponent from "./assets/theme/ConfigProvider";
import "./index.scss";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  // <Logtail.LogtailProvider cilent={logger} >
  <ConfigProviderComponent>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </ConfigProviderComponent>
  // </Logtail.LogtailProvider>
);
