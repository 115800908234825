import React from 'react';
import { Input as AntdInput } from 'antd';
import './CustomTextArea.scss'; 
// Import your SCSS styles

const { TextArea } = AntdInput;

const CustomTextArea = ({ className, onChange, ...restProps }) => {
  const inputClassName = `custom-textarea ${className || ''}`;

  return <TextArea className={inputClassName} onChange={onChange} {...restProps} />;
};

export default CustomTextArea;

