import React from "react";
import "./SuccessPage.scss";

const AlreadySubmittedPage = () => {
  return (
    <div className="result-page already-submitted-page">
      <h1>Already Submitted</h1>
      <p>
        Your form has already been submitted. Thank you for your participation.
      </p>
    </div>
  );
};

export default AlreadySubmittedPage;
