// SuccessPage.js
import React from "react";
import "./SuccessPage.scss";

const SuccessPage = () => {
  return (
    <div className="success-page">
      <h1>Success!</h1>
      <p>Your form has been successfully submitted.</p>
    </div>
  );
};

export default SuccessPage;
