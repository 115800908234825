import React from 'react';
import { Radio } from 'antd';
import './CustomRadio.scss'; 
// Import your SCSS styles

const CustomRadio = ({ options, type, value, onChange, optionType, buttonStyle, ...restProps }) => {
  const radioOptions = options.map(option => ({ label: option.label, value: option.value, disabled: option.disabled }));

  return (
    <Radio.Group
      options={radioOptions}
      onChange={onChange}
      value={value}
      optionType={optionType}
      buttonStyle={buttonStyle}
      {...restProps}
    />
  );
};

export default CustomRadio;
