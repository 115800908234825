import { Card, Checkbox, Spin } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useNavigate, useParams } from "react-router-dom";
// import { getForm } from "../../../../store/forms/formsSlice";
import CustomButton from "../components/common/CustomButton";
import CustomNotification from "../components/common/CustomNotification";
import CustomRadio from "../components/common/CustomRadio";
import CustomTextArea from "../components/common/CustomTextArea";
import "./assesmentQuestionnairesPage.scss";

const baseURL = process.env.REACT_APP_API_BASE_URL;
const AssesmentQuestionnairesPage = () => {
  //   const [answers, setAnswers] = useState({});
  const [isButtonLoading, setisButtonLoading] = useState(false);
  const [assesmentanswers, setAssesmentanswers] = useState();
  const [answers, setAnswers] = useState({});
  const [formDatainfo, setFormDatainfo] = useState(null);

  const navigate = useNavigate();
  const { formid = 1, patient_id = 43, episode_id = 252 } = useParams();

  useEffect(() => {
    if (!formid || !patient_id || !episode_id) {
      navigate("/not-found"); // or set a state to display a "Not Found" UI
    } else {
      const fetchFormStatus = async () => {
        try {
          const endpointURL = `/patient/episode/careplan/intervention/form/patient/submit/status/${+episode_id}`;
          const response = await axios.get(`${baseURL}${endpointURL}`);
          //   setIsFormSubmitted(response.data.status === "submitted");
          console.log("response", response);
          if (!response.data.status) {
            navigate("/submitted");
          }
        } catch (error) {
          if (!error.response.data.status) {
            navigate("/submitted");
          }
          console.error("Error fetching form status:", error.response.data);
        }
      };

      const fetchFormData = async () => {
        try {
          const response = await axios.get(
            `${baseURL}/org/form/patient/${formid}`
          );
          setFormDatainfo(response.data);
        } catch (error) {
          console.error("Error fetching form data:", error);
        }
      };

      if (!formDatainfo) {
        fetchFormData();
      }

      fetchFormStatus();
    }
  }, [formid, formDatainfo, patient_id, episode_id]);

  useEffect(() => {
    const initialAssessmentAnswers = {
      form_sections: formDatainfo?.form_sections?.map((section) => ({
        ...section,
        questions: section?.questions?.map((question) => ({
          ...question,
          selected_answer: question.selected_answer || [], // or use the appropriate default value
        })),
      })),
    };

    setAssesmentanswers(initialAssessmentAnswers);
  }, [formDatainfo]);

  const handleSelectionChange = (sectionIndex, questionIndex, value) => {
    setAnswers((prevAnswers) => {
      const updatedAnswers = {
        ...prevAnswers,
        [`${sectionIndex}-${questionIndex}`]: value.target.value,
      };
      return updatedAnswers;
    });

    setAssesmentanswers((prevAssessmentAnswers) => {
      const updatedAssessmentAnswers = {
        ...prevAssessmentAnswers,
        form_sections: prevAssessmentAnswers.form_sections.map(
          (section, sIndex) => {
            if (sIndex === sectionIndex) {
              return {
                ...section,
                questions: section.questions.map((question, qIndex) => {
                  if (qIndex === questionIndex) {
                    return {
                      ...question,
                      selected_answer: [value.target.value], // or use the appropriate format for selected_answer
                    };
                  }
                  return question;
                }),
              };
            }
            return section;
          }
        ),
      };

      return updatedAssessmentAnswers;
    });
  };
  const handleCheckSelectionChange = (
    sectionIndex,
    questionIndex,
    checkedValues
  ) => {
    setAnswers((prevAnswers) => {
      const updatedAnswers = {
        ...prevAnswers,
        [`${sectionIndex}-${questionIndex}`]: checkedValues,
      };
      return updatedAnswers;
    });

    setAssesmentanswers((prevAssessmentAnswers) => {
      const updatedAssessmentAnswers = {
        ...prevAssessmentAnswers,
        form_sections: prevAssessmentAnswers.form_sections.map(
          (section, sIndex) => {
            if (sIndex === sectionIndex) {
              return {
                ...section,
                questions: section.questions.map((question, qIndex) => {
                  if (qIndex === questionIndex) {
                    return {
                      ...question,
                      selected_answer: checkedValues,
                    };
                  }
                  return question;
                }),
              };
            }
            return section;
          }
        ),
      };

      return updatedAssessmentAnswers;
    });
  };
  const handleAssementSubmit = async () => {
    // Validation check
    const firstErrorQuestion = assesmentanswers.form_sections.find((section) =>
      section.questions.find(
        (question) =>
          !question.selected_answer || question.selected_answer.length === 0
      )
    );

    if (firstErrorQuestion) {
      const firstErrorField = firstErrorQuestion.questions.find(
        (question) =>
          !question.selected_answer || question.selected_answer.length === 0
      );
      // Show validation error with specific question name
      CustomNotification(
        "error",
        "Validation Error",
        `Please select an answer for '${firstErrorField.question}'.`
      );
      return; // Stop submission if validation fails
    }
    setisButtonLoading(true);
    const data = {
      form_sections: assesmentanswers.form_sections.map((section) => ({
        section_header: section.section_header,
        questions: section.questions.map((question) => ({
          question: question.question,
          answer_type: question.answer_type,
          answer_options: question.answer_options.map((option) => ({
            option: option.option,
            score: option.score,
          })),
          selected_answer: question.selected_answer || [],
          score:
            question.answer_options.find(
              (option) => option.option === question?.selected_answer?.[0]
            )?.score || 0,
          is_required: question.is_required || false,
        })),
      })),
      episode_id: +episode_id,
      patient_id: +patient_id,
      form_id: +formid,
    };

    data.total_score = data.form_sections.reduce((acc, section) => {
      const sectionScore = section.questions.reduce((sectionAcc, question) => {
        return sectionAcc + question.score;
      }, 0);
      return acc + sectionScore;
    }, 0);

    const baseURL = process.env.REACT_APP_API_BASE_URL; // Set your base URL here
    const endpointURL = `/patient/episode/careplan/intervention/form/patient/submit/${+episode_id}`;

    try {
      const response = await axios.put(`${baseURL}${endpointURL}`, data);
      setisButtonLoading(false);
      navigate("/success");
    } catch (error) {
      CustomNotification(
        "error",
        "Submission Error",
        "Failed to submit assessment form"
      );
      setisButtonLoading(false);
    }
  };

  return (
    <div className="assesments_questionnaires_container">
      {formDatainfo?.name ? (
        <div className="assesments_questionnaires_info">
          <div className="header_block">
            <h4>{formDatainfo.name}</h4>
          </div>
          <div className="questionnaires_content">
            <div className="qtn_fields">
              {formDatainfo?.form_sections?.map((section, sectionIndex) => (
                <div key={sectionIndex}>
                  <p>{section.section_header}</p>
                  {section.questions.map((question, questionIndex) => (
                    <Card
                      key={questionIndex}
                      title={`${questionIndex + 1} of ${
                        section.questions.length
                      }`}
                    >
                      <div>
                        <h3>{question.question}</h3>
                        {question.answer_type === "One Answer" && (
                          <CustomRadio
                            options={question.answer_options.map((option) => ({
                              label: option.option,
                              value: option.option,
                            }))}
                            value={answers[`${sectionIndex}-${questionIndex}`]}
                            onChange={(value) =>
                              handleSelectionChange(
                                sectionIndex,
                                questionIndex,
                                value
                              )
                            }
                          />
                        )}
                        {question.answer_type === "Multiple Answers" && (
                          <Checkbox.Group
                            options={question.answer_options.map((option) => ({
                              label: option.option,
                              value: option.id.toString(),
                            }))}
                            value={
                              answers[`${sectionIndex}-${questionIndex}`] || []
                            }
                            onChange={(checkedValues) =>
                              handleSelectionChange(
                                sectionIndex,
                                questionIndex,
                                checkedValues
                              )
                            }
                          />
                        )}
                        {question.answer_type === "Text" && (
                          <CustomTextArea
                            value={answers[`${sectionIndex}-${questionIndex}`]}
                            onChange={(value) =>
                              handleSelectionChange(
                                sectionIndex,
                                questionIndex,
                                value
                              )
                            }
                          />
                        )}
                      </div>
                    </Card>
                  ))}
                </div>
              ))}
            </div>
          </div>
          <div className="footer_block">
            {/* <CustomButton onClick={handleNext}>Next</CustomButton> */}
            <CustomButton
              onClick={handleAssementSubmit}
              loading={isButtonLoading}
            >
              Submit
            </CustomButton>
          </div>
        </div>
      ) : (
        <div className="no-data-container">
          <Spin size="large" />
          <p className="message">No data to display. Please try again later.</p>
        </div>
      )}
    </div>
  );
};

export default AssesmentQuestionnairesPage;
