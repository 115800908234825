// App.js
import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import AlreadySubmittedPage from "./AlreadySubmittedPage/AlreadySubmittedPage";
import AssesmentQuestionnairesPage from "./AssesmentQuestionnairesPage/AssesmentQuestionnairesPage";
import NotFoundPage from "./NotFoundPage/NotFoundPage";
import SuccessPage from "./SuccessPage/SuccessPage";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route
          path="/form/:formid/:patient_id/:episode_id"
          element={<AssesmentQuestionnairesPage />}
        />
        <Route path="/not-found" element={<NotFoundPage />} />
        <Route path="/success" element={<SuccessPage />} />
        <Route path="/submitted" element={<AlreadySubmittedPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
};

export default App;
