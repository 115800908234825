import { Button as AntdButton } from "antd";
import React from "react";

import "./CustomButton.scss";

const CustomButton = ({
  type = "default",
  shape,
  icon,
  isButtonLoading = false,
  loading = false,
  children,
  disabled,
  outline = false, // New prop for outline style
  background = false, // New prop for background style
  bgColor, // New prop for background color
  textColor, // New prop for text color
  borderColor,
  ...restProps
}) => {
  const buttonStyle = outline
    ? "outline-button"
    : background
    ? "background-button"
    : "";

  return (
    <AntdButton
      className={buttonStyle} // Apply custom class based on button style
      type={type}
      shape={shape}
      icon={icon}
      loading={loading || isButtonLoading}
      disabled={disabled}
      style={{
        backgroundColor: bgColor,
        color: textColor,
        borderColor: borderColor,
      }} // Apply custom colors
      {...restProps}
    >
      {children}
    </AntdButton>
  );
};

export default CustomButton;
